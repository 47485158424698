exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aftercare-js": () => import("./../../../src/pages/aftercare.js" /* webpackChunkName: "component---src-pages-aftercare-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-rubylux-js": () => import("./../../../src/pages/rubylux.js" /* webpackChunkName: "component---src-pages-rubylux-js" */),
  "component---src-pages-seminyak-js": () => import("./../../../src/pages/seminyak.js" /* webpackChunkName: "component---src-pages-seminyak-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-ubud-js": () => import("./../../../src/pages/ubud.js" /* webpackChunkName: "component---src-pages-ubud-js" */)
}

